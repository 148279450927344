
import React from 'react'
import { Link } from 'gatsby'
import { graphql, StaticQuery } from 'gatsby'
import moment from 'moment'

import Layout from "../components/layout"
import SEO from "../components/seo"

const Blog = (props) => {
  const { data } = props
  const { edges } = data.allMarkdownRemark
  return (
    <Layout>
      <SEO title="Blog" />
      <div className="blog">
        {edges.map((data, index) => (
          <div key={index} className="blog--content">
            <Link to={data.node.fields.slug}>
              <h1 className="mb--0-5">{data.node.frontmatter.title}</h1>
            </Link>
            <div className="description--wrapper">
              <label>
                {data.node.frontmatter.description ? (data.node.frontmatter.description) : "To lazy to create description :)"}
              </label>
              <small>{moment(data.node.frontmatter.date).format('DD MMM YYYY')}</small>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query GetAllBlog {
        allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "blog-post"}}}) {
          edges {
            node {
              id
              html
              frontmatter {
                date
                description
                templateKey
                title
              }
              fields {
                slug
              }
            }
          }
          totalCount
        }
      }
    `}
    render={(data) => <Blog data={data}/>}
  />
)